import {FunctionComponent, MouseEventHandler} from "react";
import clsx from "clsx";

interface IProps {
	onClick?: MouseEventHandler<HTMLButtonElement>;
	type?: ButtonTypes;
	disabled?: boolean;
	className?: string;
}

export enum ButtonTypes {
	Submit = 'submit',
	Normal = 'button',
}

const Button: FunctionComponent<IProps> = ({children, onClick, type = ButtonTypes.Normal, disabled, className = ''}) => {
	return (
		<button disabled={disabled} onClick={onClick} className={clsx('btn', className)} type={type}>{children}</button>
	);
}

export default Button;
