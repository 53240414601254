import React, {FC, useMemo} from "react";
import { Navigate} from "react-router-dom";
import {useToken} from "@utilities/helpers";
import {RoutesEnum} from "@utilities/enums";

const PrivateRoute: FC = ({ children }) => {
  const userToken = useToken();
  const canAccessPage = useMemo(() => !!userToken, [userToken]);
  if (!canAccessPage) {
    return <Navigate to={RoutesEnum.Register} state={{redirect: true}} />
  }
  return <>{children}</>;
};

export default PrivateRoute;