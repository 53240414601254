import {FC} from "react";

const TableRow: FC = ({children}) => {
  return(
    <div className='table--row'>
      {children}
  </div>
  );
}

export default TableRow;
