import { ChangeEventHandler, FC, useCallback, useMemo, useState } from 'react'
import { getGameThumbnail, imgOnError, useToken } from '@utilities/helpers'
import GameLobbyLayout from '@layouts/GameLobbyLayout'
import Input from '@components/form/Input'
import Button, { ButtonTypes } from '@components/Button'
import debounce from 'lodash.debounce'
import { getGameLink } from '@utilities/requests'
import useDevice from "../hooks/useDevice";
import Description from "@components/Description";

const GAMES = [
  {
    name: 'Fisherman\'s Fury Bonanza',
    code: 'sw_ffb_965'
  }
]
const GameLobby: FC = () => {
  const userToken = useToken()
  const [search, setSearch] = useState('');
  const {isMobile} = useDevice();

  const data = useMemo(() => {
    if (search) {
      const searchLowerCase = search.toLowerCase()
      return GAMES.filter((game) => game.name.toLowerCase().includes(searchLowerCase) || game.code.toLowerCase().includes(searchLowerCase))
    }
    return GAMES
  }, [GAMES, search])

  const onSearch = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSearch(e.target.value || '')
  }, [])
  function openTab(url: string) {
    let newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null
    } else {
      window.location.assign(url)
    }
  }
  const openGame = (gameId: string) => async () => {
    const gameLink = await getGameLink(userToken, gameId)
    if (gameLink) {
      openTab(gameLink)
    }
  }

  return (
    <GameLobbyLayout>
      {/*<form className="game-lobby--search">*/}
      {/*  <Input placeholder="Search for games..." onChange={debounce(onSearch, 100)}/>*/}
      {/*  <Button disabled={search === ''} className="btn-search" type={ButtonTypes.Submit} onClick={() => {*/}
      {/*  }}>Search</Button>*/}
      {/*</form>*/}
      <Description />
      <div className="game-lobby--body">
        {data.map((game, idx) => (
          <div key={idx} onClick={openGame(game.code)} className="game-lobby--body-item"
               // style={{backgroundImage: `url('${getGameThumbnail(game.code)}')`}}
          >
            <img src={getGameThumbnail(game.code, false)} alt="game-thumb"/>
            {/*<div>{game.name}</div>*/}
          </div>
        ))}
      </div>
    </GameLobbyLayout>
  )
}

export default GameLobby
