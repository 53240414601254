import {ITab} from "@utilities/interfaces";
import {FunctionComponent, useState} from "react";
import Button from "@components/Button";
import clsx from "clsx";

interface IProps {
  tabs: ITab[];
  defaultIndex?: number;
}

const Tabs: FunctionComponent<IProps> = ({tabs, defaultIndex = 0}) => {
  const [activeTab, setActiveTab] = useState(defaultIndex);
  const changeTab = (tab: number) => () => {
    setActiveTab(tab);
  }
  return (
    <div className='tabs'>
      <div className='tabs-header'>
        {tabs.map((tab) => (
          <div key={tab.index} className={clsx('tabs-header--item', tab.index === activeTab && 'tabs-header--item-active')}>
          <Button onClick={changeTab(tab.index)}>{tab.title}</Button>
          </div>
        ))}
      </div>
      <div className='tabs-body'>
        {tabs.map((tab) => (
          <div key={tab.index} className={clsx('tabs-body--item', !(tab.index === activeTab) && 'tabs-body--hidden')}>
            {tab.Content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tabs;
