import {
	ChangeEventHandler, FC,
	FormEventHandler,
	useCallback,
	useRef,
	useState
} from "react";
import {IFormState} from "@utilities/interfaces";
import RegisterLayout from "@layouts/RegisterLayout";
import Input from "@components/form/Input";
import Checkbox from "@components/form/Checkbox";
import Button, {ButtonTypes} from "@components/Button";
import {EMAIL_REGEX} from "@utilities/constants";
import {removeItem, setItem} from "@utilities/localStorage";
import {registerPlayer} from "@utilities/requests";
import {LocalStorageKeys, RoutesEnum} from "@utilities/enums";
import {useNavigate} from "react-router-dom";
import { getAssetUrl } from '@utilities/helpers'
import Description from "@components/Description";
import snow_button_left from "../assets/snow_button_left.svg";
import snow_button_right from "../assets/snow_button_right.svg";
import snow_form_left from "../assets/snow_form_left.svg";
import snow_form_right from "../assets/snow_form_right.svg";

const Register: FC = () => {
	const navigation = useNavigate();
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [role, setRole] = useState('')
	const [tcAccepted, setTcAccepted] = useState(false);
	const [ageOver18, setAgeOver18] = useState(false);
	const [formState, setFormState] = useState<IFormState>({
		isDirty: false,
		submitCount: 0,
		isSubmitting: false,
		errors: {},
	});
	const {errors} = formState;
	const emailRef = useRef<HTMLInputElement>(null);

	const onEmailChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
		setFormState((prevState) => ({...prevState, errors: {...prevState.errors, email: ''}}));
		setEmail(e.target.value);
	}, []);

	const onNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
		setFormState((prevState) => ({...prevState, errors: {...prevState.errors, name: ''}}));
		setName(e.target.value);
	}, []);

	const onRoleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
		setFormState((prevState) => ({...prevState, errors: {...prevState.errors, role: ''}}));
		setRole(e.target.value);
	}, []);

	const onTcChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
		setFormState((prevState) => ({...prevState, isDirty: true}));
		setTcAccepted(e.target.checked);
	}, []);

	const onAgeConsentChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		setFormState((prevState) => ({...prevState, isDirty: true}));
		setAgeOver18(e.target.checked);
	}

	const onSubmit: FormEventHandler<HTMLFormElement> = (async (e) => {
		e.preventDefault();

		setFormState((prevState) => ({
			...prevState,
			isDirty: true,
			submitCount: prevState.submitCount + 1,
		}));
		const isEmailValid = EMAIL_REGEX.test(email);
		if (!isEmailValid || name.length === 0) {
			setFormState((prevState) => {
        const emailError = 'Email is invalid';
        const nameError = 'Name should be filled';
        return {
				...prevState,
				errors: {
          ...prevState.errors,
          name: name.length === 0 ? nameError : '',
          email: !isEmailValid ? emailError : ''
        }
			}});
			return;
		}

		if (!tcAccepted || !ageOver18) {
			return;
		}

		const user = {email, userId: name, role};
		setFormState((prevState) => ({...prevState, isSubmitting: true, submitCount: prevState.submitCount + 1, errors: {}}));

		try {
			const token = await registerPlayer(user);
			if (token) {
				setItem(LocalStorageKeys.UserToken, token);
				navigation(RoutesEnum.GameLobby);
			}
		} catch(err) {
			removeItem(LocalStorageKeys.UserToken);
		}
    setFormState((prevState) => ({...prevState, isSubmitting: false, errors: {}}));
	});

	return (
		<RegisterLayout>
      <Description />
			<div className='register-form-wrapper'>
			<form className='register-form' onSubmit={onSubmit}>
				<div className="register-form-fields">
					<Input placeholder='Your email' value={email} error={errors?.email} ref={emailRef} onChange={onEmailChange} />
					<Input placeholder='Full Name' value={name} error={errors?.name} onChange={onNameChange} />
					<Input placeholder='Role' value={role} error={errors?.role} onChange={onRoleChange} />
				</div>
				<div className="register-form-checkboxes">
					<Checkbox name="tcAgreement" onChange={onTcChange} checked={tcAccepted} option={<span className="checkbox_text">I understand and agree with <a target='_blank' href={getAssetUrl('Entain T&C.pdf')}>Terms & Conditions</a> and <a target='_blank' href='https://www.skywindgroup.com/en/policy'>Privacy Policy.</a></span>} />
					<Checkbox name="ageOver18" onChange={onAgeConsentChange} checked={ageOver18} option={<span className="checkbox_text">I confirm that I am over 18 years old</span>} />
				</div>
				<Button className='btn-full btn-large' disabled={formState.isSubmitting} type={ButtonTypes.Submit}>ENTER EVENT</Button>
			</form>
			</div>
		</RegisterLayout>
	);
}

export default Register;
