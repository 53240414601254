import { get, post } from './api'
import { IGetGames, IGetUserInfo, IRegisteredUser, IUser, IWinnerUser } from '@utilities/interfaces'

const BASIC_TOKEN = "UGF2ZWw6MTIz";
export const registerPlayer = async (user: IUser): Promise<string> => {
  try {
    const response = await post<IUser, string>('/auth', user)
    return response.data
  } catch (err) {
    console.error(err)
    return ''
  }
}

export const getGames: IGetGames = async (token, options) => {
  try {
    const response = await get<{ games: string[] }>('/games?limit=10')
    return response.data
  } catch (err) {
    return {games: []}
  }
}

export const getGameLink = async (token: string, gameId: string) => {
  try {
    const response = await get<string>(`/games/url?gameId=${gameId}&token=${token}`)
    return response.data
  } catch (err) {
    console.error(err)
    return ''
  }
}

export const getUserInfo = async (token: string) => {
  try {
    const response = await get<IGetUserInfo>(`/user/info?token=${token}`)
    return response.data
  } catch (err) {
    return {userId: '', balance: {key: 0}}
  }
}

export const getRegisteredUsers = async (): Promise<IRegisteredUser[]> => {
  try {
    const response = await get<IRegisteredUser[]>(`/real/users`, {
        headers: {
          Authorization: `Basic ${BASIC_TOKEN}`
        }
      }
    )
    return response.data
  } catch (err) {
    return []
  }
}

export const getWinners = async (): Promise<IWinnerUser[]> => {
  try {
    const response = await get<IWinnerUser[]>(`/real/winners`, {
				headers: {
					Authorization: `Basic ${BASIC_TOKEN}`
				}
			}
		)
    return response.data
  } catch (err) {
    return []
  }
}
