export enum RoutesEnum {
	GameLobby = '/',
	Register = '/register',
  AdminUsers = '/admin/users',
}

export enum LocalStorageKeys {
	UserCreds = 'userCreds',
	UserToken = "token",
}
