import {FC} from "react";
import SkywindLogo from "@assets/SkywindLogo.svg";
import Navbar from "@components/GameLobby/Navbar";

const RegisterLayout: FC = ({children}) => {
	return (
    <>
      <Navbar />
      <div className='main-container'>
        {/*<img className='main-container-logo' alt='skywind-logo' src={SkywindLogo} />*/}
        {children}
      </div>
    </>
	);
}

export default RegisterLayout;
