import {IList} from "@components/Footer/Footer";
import linkedin from "@assets/socialNetworksSvg/linkedin.svg";
import youtube from "@assets/socialNetworksSvg/youtube.svg";
import instagram from "@assets/socialNetworksSvg/instagram.svg";
import facebook from "@assets/socialNetworksSvg/facebook.svg";
import tiktok from "@assets/socialNetworksSvg/tiktok.svg";
import giphy from "@assets/socialNetworksSvg/giphy.svg";
import twitter from "@assets/socialNetworksSvg/twitter.svg";

export const socialNetworksList: Array<IList> = [
    {
        title: "Linkedin",
        isLink: true,
        href: "https://www.linkedin.com/company/skywind-group/",
        image: linkedin,
        imageText: "Linkedin"
    },
    {
        title: "Youtube",
        isLink: true,
        href: "https://www.youtube.com/c/SkywindGroup",
        image: youtube,
        imageText: "Youtube"
    },
    {
        title: "Instagram",
        isLink: true,
        href: "https://www.instagram.com/skywind.group/",
        image: instagram,
        imageText: "Instagram"
    },
    {
        title: "Facebook",
        isLink: true,
        href: "https://www.facebook.com/SkywindGroup/",
        image: facebook,
        imageText: "Facebook"
    },
    {
        title: "Tiktok",
        isLink: true,
        href: "https://www.tiktok.com/@skywind_group?lang=en",
        image: tiktok,
        imageText: "Tiktok"
    },
    {
        title: "Giphy",
        isLink: true,
        href: "https://giphy.com/Skywindgroup",
        image: giphy,
        imageText: "Giphy"
    },
    {
        title: "Twitter",
        isLink: true,
        href: "https://twitter.com/skywind_group?lang=en",
        image: twitter,
        imageText: "Twitter"
    },
    {
        title: "© ALL RIGHTS RESERVED SKYWIND 2021",
    },
];
