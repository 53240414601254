import fishermanLogo from "../assets/fishermanTopLogo.svg";
import topText from "../assets/topText.svg";

const Description = () => {
  return (
    <div className='description'>
      <img src={fishermanLogo} alt='' className="description-top-logo" />
      <img src={topText} alt='' className="description--title" />
      <p className="description--cursive">
        Exclusive Event
      </p>
      <p className='description--gold get-ready'>
        GET READY TO PLAY FOR SOME GREAT PRIZES.
      </p>
      <br/>
      <p className='description--white'><span className="gold-text">The rules are simple:</span> Spin & play your way up the leaderboard for the chance at a top 3 prizes.
        <br/>
        Here's some fisherman's delight: you might land one of 7 Lucky Envelopes just by participating.
      </p>
      <p className='description--white time'>
        Play starts on Friday, October 20, 2023 at 9am and ends 6pm (Central European Time (GMT+01:00)).
      </p>
      <br/>
      <p className='description--gold'>ENJOY THE DAY, AND BEST OF LUCK !</p>
    </div>
  );
}

export default Description;
