import {ChangeEventHandler, forwardRef} from "react";

interface IProps {
	name: string;
	option: string | JSX.Element;
	onChange: ChangeEventHandler<HTMLInputElement>;
	checked?: boolean;
	error?: string;
};

const Checkbox = forwardRef<HTMLInputElement, IProps>(({name, option, error, onChange, checked}, ref) => {
	return (
		<div className='form-checkbox-container'>
			<label className='form-label'>
				<div className='form-checkbox-wrapper'>
					<input name={name} type='checkbox' onChange={onChange} checked={checked} className='form-checkbox' ref={ref} />
				</div>
        {typeof option === 'string' && <span>{option}</span>}
        {typeof option !== 'string' && <>{option}</>}
			</label>
			{error && <div className='form-error'>{error}</div>}
		</div>
	);
});

export default Checkbox;
