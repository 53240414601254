import {envVariables, IAppConfiguration} from "@utilities/interfaces";

export const getEnvParam = (param: envVariables) => {
	try {
		return process && process.env[param];
	} catch (e) {
		return undefined;
	}
}

const getConfiguration = (): IAppConfiguration => {
	return {
		nodeEnv: getEnvParam(envVariables.nodeEnv) as string,
		testSiteDomain: getEnvParam(envVariables.testSiteDomain) as string,
		brandSecretKey: getEnvParam(envVariables.brandSecretKey) as string,
		brandUsername: getEnvParam(envVariables.brandUsername) as string,
		brandPassword: getEnvParam(envVariables.brandPassword) as string,
		gameLibrary: getEnvParam(envVariables.gameLibrary) as string,
	}
};

export default getConfiguration;
