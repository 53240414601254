import {FC} from "react";
import Navbar from "@components/GameLobby/Navbar";

const GameLobbyLayout: FC = ({children}) => {
	return (
		<div className='game-lobby'>
			<Navbar/>
			{children}
		</div>
	);
}

export default GameLobbyLayout;
