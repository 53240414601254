import {IList} from "@components/Footer/Footer";

export const policyList: Array<IList> = [
    {
        title: "Privacy Policy",
        isLink: true,
        href: "https://skywindgroup.com/en/policy.html"
    },
    {
        title: "Cookie Policy",
        isLink: true,
        href: "https://skywindgroup.com/en/cookies.html"
    }
];
