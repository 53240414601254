export interface AuthRequest {
	email: string;
	password: string;
}

export interface SignupRequest extends AuthRequest {
	gender: string;
	username: string;
	image_url: string;
	last_name: string;
	first_name: string;
}

export interface IAppConfiguration {
	nodeEnv: string;
	testSiteDomain: string;
	brandSecretKey: string;
	brandUsername: string;
	brandPassword: string;
	gameLibrary: string;
}

export enum envVariables {
	nodeEnv = "NODE_ENV",
	testSiteDomain = "REACT_APP_TEST_SITE_DOMAIN",
	brandSecretKey = "REACT_APP_BRAND_SECRET_KEY",
	brandUsername = "REACT_APP_BRAND_USERNAME",
	brandPassword = "REACT_APP_BRAND_PASSWORD",
	gameLibrary = "REACT_APP_GAME_LIBRARY"
}

export interface IFormState {
	isDirty: boolean;
	submitCount: number;
	isSubmitting: boolean;
	errors: IFormErrors;
}

export interface IFormErrors {
  [fieldName: string]: string;
}

export type IGetGames = (token: string, options?: IGetGamesOptions) => Promise<{games: string[]}>;

export interface IGetGamesOptions {
	limit?: number;
	shortInfo?: boolean;
}

export interface IGame {
	title: string;
	code: string;
}

export enum RandomType {
	username = 'username',
	password = 'password',
}

export interface IUser {
	email: string;
	userId: string;
  role?: string;
}

export enum FeatureTypes {
	prizeDrop = "prize_drop",
	tournament = "tournament",
}

export interface IGetUserInfo {
	userId: string,
	balance: {
		[key: string]: number
	}
}

export interface ITab {
  index: number;
  title: string;
  Content: JSX.Element;
}

export interface ITableHeader {
  header: string;
}

export interface IRegisteredUser {
  brandId: string;
  userId: string;
  email: string
  role: string;
  firstSeen?: string;
  lastSeen?: string;
  name: string;
}

export interface DBPayment {
  _id: string;
  brandId: string;
  userId: string;
  amount: number;
  currency: string;
  featureInstanceId: string;
  featureId: string;
  featureName: string;
  featureType: string
  status: string;
  created: Date;
  updated: Date;
}

export interface IWinnerUser extends DBPayment {
  email: string;
  role: string;
  name: string;
  textPrize: string;
}

export interface IUserHeader {
  userId: string;
  email: string;
  role: string;
  name: string;
  textPrize: string;
}
