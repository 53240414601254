import {FC, useMemo} from "react";
import { getAssetUrl, getUserData } from '@utilities/helpers'

const Navbar: FC = () => {
	const user = useMemo(() => getUserData(), []);
	return (
		<div className='navbar'>
			<video autoPlay muted loop playsInline id="myVideo" className={'desktop-video'}>
				<source src={getAssetUrl('banner.mp4')} type="video/mp4"/>
			</video>
			<video autoPlay muted loop playsInline id="myVideo" className={'mobile-video'}>
				<source src={getAssetUrl('mobileBanner.mp4')} type="video/mp4"/>
			</video>
		</div>
	);
}

export default Navbar;
