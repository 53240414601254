import {useEffect, useMemo, useState} from "react";
import {IRegisteredUser, ITableHeader, IUserHeader} from "@utilities/interfaces";
import Table from "@components/table/Table";
import TableRow from "@components/table/TableRow";
import TableData from "@components/table/TableData";
import {getRegisteredUsers} from "@utilities/requests";
import Button from "@components/Button";
import {downloadCsv, generateCSV} from "@utilities/helpers";

const Players = () => {
  const [users, setUsers] = useState<IRegisteredUser[]>([]);
  useEffect(() => {
    (async () => {
      const users = await getRegisteredUsers();
      if (users.length) {
        setUsers(users);
      }
    })();
    return () => setUsers([]);
  }, []);
  const columns = useMemo<ITableHeader[]>(() => [
    {
      header: "Player code"
    },
    {
      header: 'Name'
    },
    {
      header: 'Email'
    },
    {
      header: 'Role'
    }
  ], []);

  const content = useMemo<{row: Partial<IRegisteredUser>[]}[]>(() => users.map((user) => ({
    row: [{userId: user.userId, email: user.email, name: user.name, role: user.role, brandId: user.brandId}]
  })), [users]);

  const onExport = () => {
    const csvData = generateCSV<Omit<IUserHeader, "textPrize">>(["userId", "name", "email", "role"], users);
    downloadCsv(csvData, "players.csv");
  }

  return (
    <div className='players-container'>
      <Button onClick={onExport}>Export CSV</Button>
      <Table columns={columns} content={content}>
        {(item, index) => (
          item.row.map((row, idx) => (
            <TableRow key={idx}>
              <TableData>{row.userId}</TableData>
              <TableData>{row.name}</TableData>
              <TableData>{row.email}</TableData>
              <TableData>{row.role}</TableData>
            </TableRow>
          ))
      )}</Table>
    </div>
  )
}

export default Players;
