import TableRow from "@components/table/TableRow";
import {ITableHeader} from "@utilities/interfaces";
import TableData from "@components/table/TableData";
import InfiniteScroll from "react-infinite-scroll-component";
import {useEffect, useRef} from "react";

interface IProps<R> {
  columns: ITableHeader[];
  content: R[];
  children: (row: R, index: number) => JSX.Element | JSX.Element[];
}

const Table = <R,>({columns, content, children}: IProps<R>) => {
  const prevContentLength = useRef(content.length);
  useEffect(() => {
    if (content.length !== prevContentLength.current) {
      prevContentLength.current = content.length;
    }
  }, [content.length]);
  return (
    <div className='table'>
      <div className='table--header'>
        <TableRow>{columns.map((column, idx) => <TableData key={idx}>{column.header}</TableData>)}</TableRow>
      </div>
      <div className='table--body'>
        {content.map((contentItem, idx) => children(contentItem, idx))}
      </div>
    </div>
  );
}

export default Table;
