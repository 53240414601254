export const getItem = (key: string) => {
	const {localStorage} = window;
	const item = localStorage.getItem(key);
	if (item) {
		return JSON.parse(item);
	}
	return {};
}

export const setItem = (key: string, value: any) => {
	const {localStorage} = window;
	localStorage.setItem(key, JSON.stringify(value));
}

export const removeItem = (key: string) => {
	const {localStorage} = window;
	localStorage.removeItem(key);
}
