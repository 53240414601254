import {useEffect, useState} from "react";
import {checkIsMobile} from "@utilities/helpers";

export default function useDevice() {
  const [deviceInfo, setDeviceInfo] = useState({
    isMobile: checkIsMobile(),
    width: 0,
    height: 0,
  });

  const onResize = () => {
    setDeviceInfo({
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth <= 640 || checkIsMobile(navigator?.userAgent)
    })
  }

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize, false);
    return () => window.removeEventListener('resize', onResize, false);
  }, []);

  return deviceInfo;
}
