import {FunctionComponent, useEffect, useLayoutEffect, useRef, useState} from "react";
import clsx from "clsx";
import FooterRow from "@components/Footer/componetns/FooterRow";
import FooterCol from "@components/Footer/componetns/FooterCol";
import {policyList} from "@components/Footer/FooterLists/policyList";
import {contactList} from "@components/Footer/FooterLists/contactList";
import {licensesList} from "@components/Footer/FooterLists/licensesList";
import {socialNetworksList} from "@components/Footer/FooterLists/socialNetworksList";
import {termsList} from "@components/Footer/FooterLists/termsList";
import Arrow from "@assets/arrow.svg";
import {setHeightOfRef} from "@utilities/helpers";
import useWindowDimensions from "@utilities/hooks";

export interface IList {
    title?:string;
    isLink?: boolean;
    href?:string;
    image?: string;
    imageText?: string;
}

const Footer: FunctionComponent = () => {
    const [open, setOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(0);
    const footerContentRef = useRef<HTMLDivElement>(null)
    const windowDimensions = useWindowDimensions();

    useEffect(() => {
        setHeightOfRef(footerContentRef,setContentHeight )
    }, [open, windowDimensions.width, windowDimensions.height])

    const toggleFooterShow = () => {
        setOpen(!open);
    }

    return (
            <footer className="footer">
                <div className="footer__base">
                    <div className={clsx("footer__title", open && "footer--hide")}>Company and contact details</div>
                    <button
                        onClick={toggleFooterShow}
                        className={clsx("footer__button", open && "footer__button--down")}
                        role="button">
                        <img src={Arrow} alt="arrow"/>
                    </button>
                </div>
                <div
                    ref={footerContentRef}
                    className="footer__content"
                    style={open ?
                        {height:`${contentHeight}px`} :
                        {height:`0px`, overflow:'hidden'}}
                >
                    <div className="footer__container">
                        <FooterRow>
                            <FooterCol title='Our Policy' list={policyList} classNames="footer__policy" />
                            <FooterCol title='Contact' list={contactList} classNames="footer__contact"/>
                            <FooterCol list={licensesList}  classNames="footer__licenses"/>
                            <FooterCol title='Stay connected' list={socialNetworksList} classNames="footer__connected"/>
                        </FooterRow>
                        <FooterRow>
                            <FooterCol list={termsList} classNames="footer__terms"/>
                        </FooterRow>
                    </div>
                </div>
            </footer>
    )
}
export default Footer;

