import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';

import {REFRESH_TOKEN_KEY, TOKEN_KEY} from './constants';
import {getEnvParam} from "@utilities/getConfiguration";
import {envVariables} from "@utilities/interfaces";

export const http = axios.create({
	baseURL: getEnvParam(envVariables.testSiteDomain)
});

export const post = <T, R>(endpoint: string, data?: T, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> =>
	new Promise((resolve, reject) =>
		http
			.post(endpoint, data, config)
			.then(resolve)
			.catch(e => reject(e?.response?.data))
	);

export const get = <R>(endpoint: string, config?: AxiosRequestConfig): Promise<AxiosResponse<R>> =>
	new Promise((resolve, reject) =>
		http
			.get(endpoint, config)
			.then(resolve)
			.catch(e => reject(e?.response?.data))
	);

export const patch = <T>(endpoint: string, data: T): Promise<AxiosResponse> =>
	new Promise((resolve, reject) =>
		http
			.patch(endpoint, data)
			.then(resolve)
			.catch(e => reject(e.response.data))
	);

http.interceptors.response.use(
	(response: AxiosResponse) => response,
	(error: any) => {
		const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

		if (!refreshToken) {
			return Promise.reject(error);
		}

		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;

			return post('auth/token')
				.then((res: any) => {
					/*eslint-disable*/
					const { access_token, threshold } = res.data;
					/*eslint-enable*/


					/*eslint-disable*/
					if (http.defaults.headers) {
						(http.defaults.headers as any)['Authorization'] = access_token;
					}
					/*eslint-enable*/

					return http(originalRequest);
				})
				.catch(Promise.reject);
		}

		return Promise.reject(error);
	}
);

export const isLoggedIn = () => true;
