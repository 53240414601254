import {FunctionComponent} from "react";
import clsx from "clsx";
import {IList} from "@components/Footer/Footer";

interface IFooterCol {
    title?: string;
    list: IList[];
    classNames?: string

}

const FooterCol: FunctionComponent<IFooterCol> = ({title, list, classNames, children}) => {
    return (
        <>
            <div className={ clsx(
                "footer__col col-lg-2 col-md-4 col-sm-8",
                classNames )}>
                {title && <div className="footer__subtitle">{title}</div>}
                    <ul className="footer__list">
                        {list.map((item) => {
                                if(!item.isLink) {
                                    return (
                                        <li key={item.title}>{
                                            item.image ?
                                                <img src={item.image} title={item.title} alt={item.title} />
                                                : item.title
                                        }
                                        </li>
                                        )
                                }
                                return (
                                    <li key={item.title}>
                                        <a target='_blank' title={item.title} rel='noreferrer' href={item.href}>
                                            {item.image ?
                                                <><img src={item.image} title={item.title} alt={item.title} /> {item.imageText}</>
                                                : item.title
                                            }
                                        </a>
                                    </li>
                                )
                        })}
                    </ul>
                </div>
        </>
    )
}
export default FooterCol;
