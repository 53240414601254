import {IList} from "@components/Footer/Footer";
import mga from "@assets/licensesImages/mga.png";
import gamblingCommission from "@assets/licensesImages/gamblingCommission.png";
import alderney from "@assets/licensesImages/alderney.png";
import juegoSeguro from "@assets/licensesImages/juegoSeguro.png";
import onjn from "@assets/licensesImages/onjn.png";
import hellenic from "@assets/licensesImages/hellenic.png";
import gibraltart from "@assets/licensesImages/gibraltart.png";
import adultOnly from "@assets/licensesImages/adultOnly.png";
import gambleAware from "@assets/licensesImages/gambleAware.png";

export const licensesList: Array<IList> = [
    {
        title: "Malta Gaming Authority",
        isLink: true,
        href: "https://www.authorisation.mga.org.mt/verification.aspx?lang=EN&company=f4d39e36-e25b-4df0-b31e-b2c35f73ca66&details=1",
        image: mga,
    },
    {
        title: "Gambling Commission of Great Britain",
        isLink: true,
        href: "https://registers.gamblingcommission.gov.uk/52261",
        image: gamblingCommission,
    },
    {
        title: "Alderney Gambling Control Commission",
        isLink: true,
        href: "https://www.gamblingcontrol.org/",
        image: alderney,
    },

    {
        title: "Spanish General Directorate For Regulation of Gambling (DGOJ)",
        isLink: true,
        href: "https://www.ordenacionjuego.es/en/operators/2523/operator",
        image: juegoSeguro,
    },
    {
        title: "Romanian National Gambling Office (ONJN)",
        isLink: true,
        href: "http://onjn.gov.ro/lista-licentiati-clasa-a-ii-a/",
        image: onjn,
    },
    {
        title: "The Hellenic Gaming Commission (HGC)",
        isLink: true,
        href: "https://www.gamingcommission.gov.gr/index.php/en/supervision-and-audit/lists-and-registers",
        image: hellenic,
    },
    {
        title: "HM Government of Gibraltar",
        isLink: true,
        href: "https://www.gibraltar.gov.gi/finance-gaming-and-regulations/remote-gambling",
        image: gibraltart,
    },
    {
        title: "18+",
        image: adultOnly,
    },
    {
        title: "BeGambleAware",
        isLink: true,
        href: "https://www.begambleaware.org/",
        image: gambleAware,
    },
];
