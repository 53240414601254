import {FunctionComponent} from "react";

const FootterRow: FunctionComponent = ({ children}) => {
    return (
        <>
            <div className="footer__row">
                {children}
            </div>
        </>
    )
}
export default FootterRow;
