import {IList} from "@components/Footer/Footer";

export const contactList: Array<IList> = [
    {
        title: "Unit 2W – Second Floor",
    },
    {
        title: "Quay House, South Quay\n",
    },
    {
        title: "Douglas IM15AR\n",
    },
    {
        title: "Isle of Man\n",
    },
    {
        title: "sw_sales@skywindgroup.com",
        isLink: true,
        href: "mailto:sw_sales@skywindgroup.com"
    },
    {
        title: "+35722053051",
        isLink: true,
        href: "tel:+35722053051"
    }
];
