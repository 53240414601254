import React from 'react';
import {Routes, Route} from "react-router-dom";
import {RoutesEnum} from "@utilities/enums";
import "@styles/main.scss";
import Register from "@routes/Register";
import GameLobby from "@routes/GameLobby";
import Users from "@routes/admin/Users";
import PrivateRoute from '@components/PrivateRoute'
import Footer from "@components/Footer/Footer";

function App() {
  return (
      <>
          <div id="background" />
          <Routes>
              <Route path={RoutesEnum.GameLobby} element={<PrivateRoute><GameLobby /></PrivateRoute>} />
              <Route path={RoutesEnum.AdminUsers} element={<Users />} />
              <Route path={RoutesEnum.Register} element={<Register />} />
          </Routes>
          <Footer />
      </>
  );
}

export default App;
