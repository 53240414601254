import { useMemo } from "react";
import {ITab} from "@utilities/interfaces";
import Tabs from "@components/Tabs";
import Winners from "@components/admin/Winners";
import Players from "@components/admin/Players";

const Users = () => {
  const tabs = useMemo<ITab[]>(() => [
    {
      index: 0,
      title: 'Winners',
      Content: <Winners />,
    },
    {
      index: 1,
      title: 'Registered Players',
      Content: <Players />
    }
  ], []);
  return (
    <div className='admin-container'>
      <div className='admin-wrapper'>
        <Tabs tabs={tabs} />
      </div>
    </div>
  );
}

export default Users;
