import Table from "@components/table/Table";
import {useEffect, useMemo, useState} from "react";
import {ITableHeader, IUserHeader, IWinnerUser} from "@utilities/interfaces";
import TableRow from "@components/table/TableRow";
import TableData from "@components/table/TableData";
import {getWinners} from "@utilities/requests";
import Button from "@components/Button";
import {downloadCsv, generateCSV} from "@utilities/helpers";

const Winners = () => {
  const [users, setUsers] = useState<IWinnerUser[]>([]);
  useEffect(() => {
    (async () => {
      const users = await getWinners();
      if (users.length) {
        setUsers(users);
      }
    })();
    return () => setUsers([]);
  }, []);
  const columns = useMemo<ITableHeader[]>(() => [
  {
    header: 'Name'
  },
  {
    header: 'Prize'
  },
  {
    header: 'Email'
  },
  {
    header: 'Role'
  },
  ], []);

  const content = useMemo<{row: Partial<IWinnerUser>[]}[]>(() => users.map((user) => ({
    row: [{email: user.email, name: user.name, textPrize: user.textPrize, role: user.role}]
  })), [users]);

  const onExport = () => {
    const csvData = generateCSV<IUserHeader>(["name", "email", "textPrize", "role"], users);
    downloadCsv(csvData, "winners.csv");
  }

  return (
    <div className='winners-container'>
      <Button onClick={onExport}>Export CSV</Button>
      <Table columns={columns} content={content}>{(item, index) => (
        item.row.map((row, idx) => (
          <TableRow key={idx}>
            <TableData>{row.name}</TableData>
            <TableData>{row.textPrize}</TableData>
            <TableData>{row.email}</TableData>
            <TableData>{row.role}</TableData>
          </TableRow>
        ))
      )}</Table>
    </div>
  )
}

export default Winners;
