import {ChangeEventHandler, forwardRef} from "react";

interface IProps {
	error?: string;
	placeholder?: string;
	label?: string;
	onChange: ChangeEventHandler<HTMLInputElement>;
	value?: string;
}

const Input = forwardRef<HTMLInputElement, IProps>(({error, placeholder = '', label = '', onChange, value}, ref) => {
	return (
		<label className='form-label'>
			{label && <div className='form-label-text'>{label}</div>}
			<div className='form-input-wrapper'>
				<input placeholder={placeholder} className='form-input' ref={ref} type='text' onChange={onChange} value={value} />
				{error && <div className='form-error'>{error}</div>}
			</div>
		</label>
	);
});

export default Input;
